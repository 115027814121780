import { Form, notification } from "antd";
import { useCallback } from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";

import {
  currentMobileNumberForUserUriState,
  currentMobileNumberState,
  customJobStatusState,
  isValidMobileNumberFromDBStateForUserUri,
  isValidPhoneNumberFromDBStateForUserUri,
  profileState,
  profileStateForUserUri,
  tanChannelState
} from "@states/profile.state";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { setInitProfileFormFields } from "@page-components/onboarding/PersonalData/helpers";
import { useApiErrorHandler } from "@src/hooks/error-handling/useApiErrorHandler";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import type ProfileDto from "smavesto.core/lib/types/dto/profile/ProfileDto";

import { loggedInUserUriState } from "@states/auth.state";
import { useRefreshState } from "@states/request.state";
import useThemedModal from "@theme/hook/useThemedModal";
import { assertNotEmpty } from "smavesto.core";
import type TanChannel from "smavesto.core/lib/types/dto/tan/TanChannel";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import { openTanModalForm } from "../helpers";
import { SUCCESS_NOTIFICATION } from "../staticNotifications";

export type FormValues = Partial<
  Omit<
    ProfileDto,
    | "email"
    | "birthDate"
    | "gender"
    | "title"
    | "firstName"
    | "lastName"
    | "birthCountry"
    | "birthCity"
    | "nationality"
    | "propertyRegime"
  >
>;

export const usePersonalDataModalForm = (
  destroyModal: () => void,
  userUri?: string
) => {
  assertNotEmpty(userUri);

  const [form] = Form.useForm();

  const [handleApiError] = useApiErrorHandler();

  const { contextHolder, openModal } = useThemedModal();

  const isMobilePhoneNumberValid = useAsyncSelector(
    isValidMobileNumberFromDBStateForUserUri(userUri)
  );

  const isPhoneNumberValid = useAsyncSelector(
    isValidPhoneNumberFromDBStateForUserUri(userUri)
  );
  const [, loggedInuserUri] = useAsyncSelector(loggedInUserUriState);

  const setCustomJobStatus = useSetRecoilState(customJobStatusState);

  const setCurrentMobileNumber = useSetRecoilState(
    userUri
      ? currentMobileNumberForUserUriState(userUri)
      : currentMobileNumberState("loggedInUser")
  );

  const resetTanChannelState = useResetRecoilState(
    tanChannelState("loggedInUser")
  );

  const refreshProfile = useRefreshState(
    "profile",
    "currentDepotOwner",
    userUri
  );

  const refreshKnowledge = useRefreshState(
    "knowledge",
    "currentDepotOwner",
    userUri
  );

  const setFormFields = useCallback(
    (res: ProfileDto | undefined) =>
      setInitProfileFormFields(form, setCustomJobStatus, {
        ...res,
        mobile: isMobilePhoneNumberValid ? res?.mobile : "+49",
        phone: isPhoneNumberValid ? res?.phone : "+49"
      } as ProfileDto),
    [form, setCustomJobStatus]
  );

  const [, profile] = useAsyncSelector(
    userUri ? profileStateForUserUri(userUri) : profileState("loggedInUser"),
    setFormFields
  );

  const updateData =
    (profile?: ProfileDto) =>
    async (
      { tan, channel }: { userUri: string; tan: string; channel: TanChannel },
      destroyTanModal: () => void
    ) => {
      if (!profile || !loggedInuserUri) return;

      const setProfileResponse = await smavestoCore.services.user.setProfile(
        userUri || loggedInuserUri,
        { ...profile },
        tan,
        channel,
        "v4"
      );

      if (isApiErrorResponse(setProfileResponse)) {
        handleApiError(setProfileResponse, "modal", "error");
        destroyModal();
        return;
      }
      refreshProfile();
      refreshKnowledge();
      destroyTanModal();
      destroyModal();
      notification.success(SUCCESS_NOTIFICATION);
    };

  const onFinish = async (values: FormValues) => {
    if (!profile || !loggedInuserUri) return;

    const mobile =
      values?.mobile && !values?.mobile.includes("+")
        ? `+${values?.mobile}`
        : values?.mobile;

    const phone =
      values?.phone && !values?.phone.includes("+")
        ? `+${values?.phone}`
        : values?.phone;

    const body: ProfileDto = {
      ...profile,
      ...values,
      birthDate: undefined,
      mobile,
      phone,
      customJobStatus: values?.customJobStatus ?? null,
      jobBranch: values?.jobBranch ?? null
    };

    resetTanChannelState();

    setCurrentMobileNumber(mobile);

    openTanModalForm(openModal, updateData(body));
  };

  const onCancel = () => {
    resetTanChannelState();
    setCurrentMobileNumber(profile?.mobile);
    destroyModal();
  };

  return { form, contextHolder, onFinish, onCancel };
};
