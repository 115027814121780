const SUCCESS_NOTIFICATION = {
  message: `Ihre Änderung war erfolgreich.`,
  description: "Ihre Daten wurden erfolgreich aktualisiert.",
  placement: "top" as const
};

const ERROR_NOTIFICATION = {
  message: `Ihre Änderung war nicht erfolgreich.`,
  description: "Ihre Daten wurden nicht aktualisiert.",
  placement: "top" as const
};

export { SUCCESS_NOTIFICATION, ERROR_NOTIFICATION };
