import type { FormInstance } from "antd/lib/form";
import moment from "moment";
import type { SetterOrUpdater } from "recoil";

import type ProfileDto from "smavesto.core/lib/types/dto/profile/ProfileDto";

export const setInitProfileFormFields = (
  form: FormInstance,
  setCustomJobStatus: SetterOrUpdater<string>,
  profile?: ProfileDto,
  isOnReset?: boolean
) => {
  setCustomJobStatus(profile?.jobStatus ?? "");

  isOnReset && form.resetFields();

  const birthDate = getInitDatePickerValue(profile?.birthDate, "DD.MM.YYYY");

  form.setFieldsValue({ ...profile, birthDate });
};

export const getInitDatePickerValue = (
  initValue?: number | string,
  format?: moment.MomentFormatSpecification
) => (initValue ? moment(new Date(initValue), format) : undefined);
