import type { FC } from "react";
import { useRecoilState } from "recoil";

import { FormGroup } from "@components/form/inputs/form-fields/FormGroup";
import { InputField } from "@components/form/inputs/form-fields/InputField";
import { SelectField } from "@components/form/inputs/form-fields/SelectField";
import { customJobStatusState } from "@states/profile.state";

import {
  INDUSTRIES,
  JOB_STATUSES,
  JOB_STATUSES_UNDERAGE,
  RULES
} from "../constants";

export interface IProps {
  initialValue?: string;
  isUnderage?: boolean;
}

export const JobStatus: FC<IProps> = ({ initialValue, isUnderage }) => {
  const [customJobStatus, setCustomJobStatus] =
    useRecoilState(customJobStatusState);

  const onJobStatusChange = (value: unknown) =>
    typeof value === "string" && setCustomJobStatus(value);

  return (
    <FormGroup spans={[{ md: 8 }, { md: 16 }]}>
      <SelectField
        label="Berufsstatus *"
        name="jobStatus"
        rules={RULES.jobStatus}
        onChange={onJobStatusChange}
        data={isUnderage ? JOB_STATUSES_UNDERAGE : JOB_STATUSES}
        initialValue={initialValue}
      />
      <div>
        {customJobStatus === "SELBSTSTAENDIGER" && (
          <SelectField
            label="Branche *"
            name="jobBranch"
            initialValue={INDUSTRIES[0].value}
            rules={RULES.jobBranch}
            data={INDUSTRIES}
          />
        )}

        {customJobStatus === "SONSTIGES" && (
          <InputField
            label="Berufsbezeichnung *"
            name="customJobStatus"
            rules={RULES.customJobStatus}
          />
        )}
      </div>
    </FormGroup>
  );
};
