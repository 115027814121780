import { MinusOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd";
import { Form, Select } from "antd";
import React from "react";

import { SelectField } from "@components/form/inputs/form-fields/SelectField";
import { SButton } from "@styled-components/form/SButtons";
import { SFormListItemWrapper } from "@styled-components/form/SFormItems";
import { SPlusOutlined } from "@styled-components/SIcons";

import { ADDITIONAL_INCOME, RULES } from "../constants";

interface AdditionalIncomeProps {
  form: FormInstance;
  selectedAdditionalIncomes: string[];
  isUnderage: boolean;
  addButtonText?: string;
}

export const AdditionalIncome: React.FunctionComponent<
  AdditionalIncomeProps
> = ({ form, selectedAdditionalIncomes, addButtonText, isUnderage }) => {
  const [additionalIncomes, setAdditionalIncomes] = React.useState(
    selectedAdditionalIncomes ?? []
  );

  React.useEffect(() => {
    setAdditionalIncomes(selectedAdditionalIncomes ?? []);
  }, [selectedAdditionalIncomes]);

  const notSelectedAdditionalIncomes = ADDITIONAL_INCOME.filter(
    e => !additionalIncomes.includes(e.value)
  );

  return (
    <Form.List name="additionalIncome">
      {(fields, { add, remove }) => {
        const onAdd = () => {
          if (notSelectedAdditionalIncomes.length) {
            const type = notSelectedAdditionalIncomes[0].value;
            add(type);
            setAdditionalIncomes(prev => [...prev, type]);
          }
        };

        const onExperienceTypeChange =
          (prevValue: string) => (value: unknown) => {
            if (typeof value === "string")
              setAdditionalIncomes(prev => [
                ...prev.filter(e => e !== prevValue),
                value
              ]);
          };

        const onRemove = (value: string, fieldName: number) => {
          setAdditionalIncomes(prev => prev.filter(e => e !== value));
          remove(fieldName);
        };

        return (
          <>
            {fields.map((field, idx) => {
              const currentIncomeTypeVal =
                form.getFieldValue("additionalIncome")?.[idx] ?? "";
              return (
                <SFormListItemWrapper key={field.key}>
                  <SelectField
                    label={`Zusatzeinkommen * ${idx + 1}`}
                    name={field.name}
                    fieldKey={field.key}
                    style={{ minWidth: "50%", width: "100%" }}
                    rules={RULES.additionalIncome}
                    initialValue={currentIncomeTypeVal}
                    onChange={onExperienceTypeChange(currentIncomeTypeVal)}
                  >
                    {ADDITIONAL_INCOME.map(({ value, label }) => (
                      <Select.Option
                        key={value}
                        value={value}
                        disabled={additionalIncomes.includes(value)}
                      >
                        {label}
                      </Select.Option>
                    ))}
                  </SelectField>

                  <MinusOutlined
                    className="minus-icon"
                    onClick={() => onRemove(currentIncomeTypeVal, field.name)}
                  />
                </SFormListItemWrapper>
              );
            })}

            <SButton
              type="text"
              className="add-button"
              disabled={!(fields.length < 5)}
              onClick={onAdd}
              icon={<SPlusOutlined />}
            >
              {addButtonText ??
                (isUnderage
                  ? "Mein Kind hat weitere Einkünfte"
                  : "Ich habe weitere Einkünfte aus anderen Tätigkeiten.")}
            </SButton>
          </>
        );
      }}
    </Form.List>
  );
};
