import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";

import theme from "@styles/theme";

export const SPlusOutlined = styled(PlusOutlined)`
  font-size: 20px;
  color: ${theme.primary};
`;
export const SMinusOutlined = styled(MinusOutlined)`
  font-size: 30px;
  color: ${theme.primary};
  margin: auto;
  display: block;
`;
