import { Skeleton } from "antd";
import { SectionWrapper } from "@page-components/onboarding/Summary/SectionWrapper";

const ProfileSectionSkeleton: React.FunctionComponent = () => {
  return (
    <SectionWrapper
      title={<Skeleton.Button active style={{ width: 300 }} />}
      editButton={<Skeleton.Button active style={{ width: 200 }} />}
    >
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </SectionWrapper>
  );
};

export default ProfileSectionSkeleton;
