import { useRef, useState } from "react";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import { userUriState } from "@states/auth.state";

import formatApiUrl from "smavesto.core/lib/utils/format/formatApiUrl";

export const useDownloadFile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [, userUri] = useAsyncSelector(userUriState("currentDepotOwner"));

  const downloadFileRef = useRef<HTMLAnchorElement>(null);

  const onDownload = async (id: string): Promise<boolean> => {
    if (!userUri) return false;
    setIsLoading(true);
    try {
      const downloadLink = getFileUrl(id);

      if (downloadFileRef.current) {
        downloadFileRef.current.href = downloadLink;
        downloadFileRef.current.click();

        URL.revokeObjectURL(downloadFileRef.current.href);
      }
    } catch (error) {
      smavestoCore.debugLog("error", error);
    } finally {
      setIsLoading(false);
    }

    return true;
  };

  const getFileUrl = (id: string): string => {
    if (!userUri) return "";

    const downloadLink = `/api/dser/${formatApiUrl(
      "v3",
      "users",
      "inbox",
      userUri,
      `download/${id}`
    )}`;

    return downloadLink;
  };

  return { isLoading, onDownload, downloadFileRef, getFileUrl };
};
