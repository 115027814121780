import PopoverInfo from "@components/form/popover-info/PopoverInfo";
import CancellationTooltip from "@components/form/tooltips/CancellationTooltip";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { LABEL_INFO } from "@page-components/onboarding/PersonalData/constants";
import { SectionWrapper } from "@page-components/onboarding/Summary/SectionWrapper";
import {
  getConfirm,
  mapDBValue,
  parseCountryCodeToLabel
} from "@page-components/onboarding/Summary/helpers";
import { AdditionalIncomeList } from "@page-components/onboarding/Summary/profile-info/AdditionalIncomeList";
import { countriesQuery } from "@states/common.state";
import {
  isValidMobileNumberFromDBStateForUserUri,
  isValidPhoneNumberFromDBStateForUserUri,
  profileStateForUserUri
} from "@states/profile.state";
import { SButton } from "@styled-components/form/SButtons";
import useThemedModal from "@theme/hook/useThemedModal";
import { bundledLoadingState } from "@utils/recoil/bundledLoadingState";

import { ProfileKeyValue } from "@page-components/onboarding/Summary/ProfileKeyValue";
import { onModalFormOpen } from "../helpers";
import ProfileSectionSkeleton from "../skeleton/ProfileSectionSkeleton";
import { EditPersonalDataModal } from "./EditPersonalDataModal";

interface PersonalDataSectionProps {
  userUri: string;
  underageMode: boolean;
  hideEditButton?: boolean;
}

const PersonalDataSection: React.FunctionComponent<
  PersonalDataSectionProps
> = ({ userUri, underageMode, hideEditButton }) => {
  const [countriesLoadingState, countries = []] =
    useAsyncSelector(countriesQuery);

  const [profileLoadingState, contents] = useAsyncSelector(
    profileStateForUserUri(userUri)
  );

  const { contextHolder, openModal } = useThemedModal();

  const [mobileNumberValidLoadingState, isMobilePhoneNumberValid] =
    useAsyncSelector(isValidMobileNumberFromDBStateForUserUri(userUri));

  const [phoneNumberValidLoadingState, isPhoneNumberValid] = useAsyncSelector(
    isValidPhoneNumberFromDBStateForUserUri(userUri)
  );

  const loadingState = bundledLoadingState([
    mobileNumberValidLoadingState,
    profileLoadingState,
    countriesLoadingState,
    phoneNumberValidLoadingState
  ]);

  return loadingState === "loading" ? (
    <ProfileSectionSkeleton />
  ) : (
    <SectionWrapper
      isEmpty={!contents}
      title={
        underageMode ? "Persönliche Angaben des Kindes" : "Persönliche Angaben"
      }
      editButton={
        !hideEditButton && (
          <CancellationTooltip>
            <SButton
              type="text"
              className="text"
              style={{ fontSize: 18 }}
              onClick={onModalFormOpen(
                openModal,
                EditPersonalDataModal,
                undefined,
                {
                  userUri
                }
              )}
            >
              bearbeiten
            </SButton>
          </CancellationTooltip>
        )
      }
    >
      {underageMode && (
        <ProfileKeyValue label="E-Mail-Adresse:" alignSelectionBottom>
          {contents?.email ?? ""}
        </ProfileKeyValue>
      )}
      <ProfileKeyValue
        label="Straße, Hausnummer:"
        alignSelectionBottom
        testId="street_div"
      >
        {contents?.street ?? ""} {contents?.streetNr ?? ""}
      </ProfileKeyValue>
      <ProfileKeyValue label="PLZ, Stadt:" testId="city_plz_div">
        {contents?.zipCode ?? ""} {contents?.city ?? ""}
      </ProfileKeyValue>
      <ProfileKeyValue label="Land:">
        {parseCountryCodeToLabel(countries, contents?.country ?? "")}
      </ProfileKeyValue>
      <ProfileKeyValue label="Telefon:">
        {contents?.phone}
        {isPhoneNumberValid === false && (
          <p style={{ fontSize: 12, color: "red", fontWeight: "normal" }}>
            {LABEL_INFO.phone.notValidHint}
          </p>
        )}
      </ProfileKeyValue>
      <ProfileKeyValue label="Telefon, mobil:">
        {contents?.mobile ?? ""}
        {!underageMode && (
          <>
            {" "}
            <PopoverInfo
              title={LABEL_INFO.mobile.title}
              text={LABEL_INFO.mobile.text.replace(
                "im vorherigen Schritt ",
                ""
              )}
            />
          </>
        )}
        {underageMode && isMobilePhoneNumberValid === false && (
          <p style={{ fontSize: 12, color: "red", fontWeight: "normal" }}>
            {LABEL_INFO.mobile.notValidUnderage}
          </p>
        )}
        {!underageMode && isMobilePhoneNumberValid === false && (
          <p style={{ fontSize: 12, color: "red", fontWeight: "normal" }}>
            {LABEL_INFO.mobile.notValidHint}
          </p>
        )}
      </ProfileKeyValue>
      <ProfileKeyValue label="Familienstand:">
        {contents?.maritalStatus
          ? mapDBValue("profile", "maritalStatus", contents?.maritalStatus)
          : ""}
      </ProfileKeyValue>
      <ProfileKeyValue label="Berufsstatus:">
        {contents?.jobStatus
          ? mapDBValue("profile", "jobStatus", contents?.jobStatus)
          : ""}
      </ProfileKeyValue>
      {contents?.jobBranch && (
        <ProfileKeyValue label="Branche:">
          {contents?.jobBranch
            ? mapDBValue("profile", "jobBranch", contents?.jobBranch)
            : ""}
        </ProfileKeyValue>
      )}
      <ProfileKeyValue label="Zusatzeinkommen:">
        {contents?.additionalIncome ? (
          !contents.additionalIncome.length ? (
            `(keine Angabe)`
          ) : (
            <AdditionalIncomeList list={contents.additionalIncome} />
          )
        ) : (
          ""
        )}
      </ProfileKeyValue>
      <ProfileKeyValue label="Politisch exponiert:">
        {getConfirm(contents?.politicallyExposed)}
        <PopoverInfo {...LABEL_INFO.politicallyExposed} />
      </ProfileKeyValue>
      {contextHolder}
    </SectionWrapper>
  );
};

export default PersonalDataSection;
