import { useAsyncSelector } from "@src/hooks/useAsyncSelector";

import { ThemeContext } from "@src/pages/_app";
import { countryCodeState } from "@states/profile.state";
import { SPhoneInput } from "@styled-components/form/SInputs";
import { useContext } from "react";
import de from "react-phone-input-2/lang/de.json";
import "react-phone-input-2/lib/plain.css";
import type { FormItemWithInfoProps } from "./FormItemWithInfo";
import FormItemWithInfo from "./FormItemWithInfo";

const PhoneField: React.FunctionComponent<FormItemWithInfoProps> = props => {
  const [, countryCode] = useAsyncSelector(countryCodeState);

  const { inputCssProperties } = useContext(ThemeContext);

  return (
    <FormItemWithInfo {...props}>
      <SPhoneInput
        localization={de}
        countryCodeEditable
        country={countryCode?.toLowerCase() || ""}
        masks={{ [countryCode?.toLowerCase() || ""]: "(...) ........" }}
        placeholder="z.B. +49171123456"
        containerStyle={inputCssProperties}
      />
    </FormItemWithInfo>
  );
};

export default PhoneField;
