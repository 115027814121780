import styled from "styled-components";

interface ProfileKeyValueProps {
  label: string | React.ReactNode;
  alignSelectionBottom?: boolean;
  testId?: string;
  children?: React.ReactNode;
}

export const ProfileKeyValue: React.FunctionComponent<ProfileKeyValueProps> = ({
  label,
  alignSelectionBottom,
  testId,
  children
}) => {
  return (
    <SProfileKeyValueItem
      alignSelectionBottom={alignSelectionBottom}
      data-testid={testId}
    >
      <div className="label">{label}</div>
      <div className="value">{children}</div>
    </SProfileKeyValueItem>
  );
};

const SProfileKeyValueItem = styled.div<{
  alignSelectionBottom?: boolean;
}>`
  @media (min-width: 960px) {
    display: flex;
  }
  font-size: 16px;

  margin-bottom: 2rem;

  .label {
    color: inherit;
    min-width: 335px;
    word-wrap: break-word;
    margin-right: 1rem;
  }

  .value {
    color: inherit;
    font-size: 1em;
    font-weight: bold;
  }
`;
