export const RULES = {
  education: [
    { required: true, message: "Bitte wählen Sie Ihren Bildungsstand aus." }
  ],

  educationCustom: [
    {
      required: true,
      message: "Bitte geben Sie Ihren sonstigen Bildungsstand ein."
    }
  ],

  knowhow: [
    { required: true, message: "Bitte teilen Sie uns Ihren Kenntnisstand mit." }
  ],

  dealings: [{ required: true, message: "Bitte wählen Sie eine Auswahl aus." }],

  noticed: [{ required: true, message: "Bitte wählen Sie eine Auswahl aus." }],

  video: [
    {
      required: true,
      transform: (value: boolean) => value || undefined,
      type: "boolean" as const,
      message: "Bitte bestätigen Sie, dass Sie das Video angesehen haben."
    }
  ],

  additionalExperienceType: [
    { required: true, message: "Bitte geben Sie weitere Anlageformen an." }
  ],

  additionalExperienceDealings: [
    { required: true, message: "Bitte wählen Sie eine Anzahl aus." }
  ]
};

export const RADIO_EDUCATION = [
  { value: "HAUPTSCHULE", label: "Hauptschule" },
  { value: "BERUFSAUSBILDUNG", label: "Berufsausbildung" },
  { value: "MITTLERE_REIFE", label: "Mittlere Reife" },
  { value: "ABITUR", label: "Abitur" },
  { value: "HOCHSCHULABSCHLUSS", label: "Hochschulabschluss" },
  { value: "SONSTIGES", label: "Sonstiges" }
];

export const RADIO_EXPERIENCE_VALUES = [
  { label: "Ja", value: true },
  { label: "Nein", value: false }
];

export const EXPERIENCE_LABELS = {
  equities: {
    knowhow: "Haben Sie Erfahrungen in Index-Aktienfonds (ETFs)? *",
    dealings: "Anzahl der Geschäfte in den letzten drei Jahren? *",
    noticed: "Kennen Sie Index-Aktienfonds? *"
  },
  bonds: {
    knowhow: "Haben Sie Erfahrungen in Index-Rentenfonds (ETFs)? *",
    dealings: "Anzahl der Geschäfte in den letzten drei Jahren? *",
    noticed: "Kennen Sie Index-Rentenfonds? *"
  },
  commodities: {
    knowhow:
      "Haben Sie Erfahrungen in Rohstoffen (Zertifikate auf Gold, Öl usw.)? *",
    dealings: "Anzahl der Geschäfte in den letzten drei Jahren? *",
    noticed: "Kennen Sie Rohstoff-Zertifikate? *"
  },
  crypto: {
    knowhow: "Haben Sie Erfahrung in Kryptowährungen (Zertifikate)? *",
    dealings: "Anzahl der Geschäfte in den letzten drei Jahren? *",
    noticed: "Kennen Sie Kryptowährungen (Zertifikate)? *"
  }
} as const;

export const EXPERIENCE_NAME_LIST = [
  "equities",
  "bonds",
  "commodities",
  "crypto"
] as const;

export const EXPERIENCE_DEALINGS_LABELS = [
  { label: "Keine", value: "NONE" },
  { label: "1", value: "ONE" },
  { label: "2 bis 5", value: "TWO_TO_FIVE" },
  { label: "6 und mehr", value: "SIX_AND_MORE" }
];

export const EXPERIENCE_DEALINGS_MARKS = {
  0: { label: "Keine", value: "NONE" },
  33: { label: "1", value: "ONE" },
  66: { label: "2 bis 5", value: "TWO_TO_FIVE" },
  100: { label: "6 und mehr", value: "SIX_AND_MORE" }
} as const;

export const DEALINGS_DICTIONARY_STR_TO_NUM = {
  NONE: 0,
  ONE: 33,
  TWO_TO_FIVE: 66,
  SIX_AND_MORE: 100
} as const;

export type DialingsMarksKeysType = keyof typeof EXPERIENCE_DEALINGS_MARKS;

export const ADDITIONAL_EXPERIENCES = [
  { label: "Anleihen", value: "anleihen" },
  { label: "Investmentfonds", value: "investmentfonds" },
  { label: "Aktien", value: "aktien" },
  { label: "Hedgefonds", value: "hedgefonds" },
  { label: "Optionsscheine", value: "optionsscheine" },
  { label: "Zertifikate", value: "zertifikate" },
  { label: "Beteiligungen", value: "beteiligungen" }
];

export const CHECKBOX_VIDEO_LABEL_DASHBOARD = (
  <>
    Ich habe das Video angesehen und den Inhalt verstanden. Weitere
    Informationen entnehme ich den Risikohinweisen in den{" "}
    <a
      rel="noreferrer"
      target="_blank"
      href="https://www.smavesto.de/files/32/01.24_kundeninformation_zum_geschaeft_classic.pdf"
    >
      Kundeninformationen
    </a>
    , welche ich auch in meinem Smavesto Postfach und auf der Website unter
    &quot;Rechtliches&quot; finde.*
  </>
);

export const CHECKBOX_VIDEO_LABEL = `Ich habe das Video angesehen und verstanden.
	Weitere Informationen entnehme ich den Risikohinweisen in den Kundeninformationen, die mir vor Vertragsabschluss zur Verfügung gestellt werden. *`;
