import type { FormItemProps, SelectProps } from "antd";
import { Select } from "antd";
import type { ReactNode } from "react";
import { useContext } from "react";

import { ThemeContext } from "@src/pages/_app";
import { SFormItem, SSelect } from "@styled-components/form/SFormItems";

interface Props<T> extends FormItemProps {
  data?: T[];
  onChange?: SelectProps["onChange"];
  children?: ReactNode | ReactNode[];
}

export function SelectField<
  T extends { value: string | number; label: string }
>({ data = [], onChange, children, ...props }: Props<T>) {
  const { inputCssProperties } = useContext(ThemeContext);

  return (
    <SFormItem {...props}>
      <SSelect style={inputCssProperties} onChange={onChange}>
        {children ||
          data.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        {}
      </SSelect>
    </SFormItem>
  );
}
