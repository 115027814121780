import { LockOutlined } from "@ant-design/icons";

import isUnderageDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import { FormGroup } from "@components/form/inputs/form-fields/FormGroup";
import { InputField } from "@components/form/inputs/form-fields/InputField";
import PhoneField from "@src/components/form/inputs/form-fields/PhoneField";
import { RadioGroup } from "@components/form/inputs/form-fields/RadioGroup";
import { SelectField } from "@components/form/inputs/form-fields/SelectField";
import { depotForUserUriState } from "@states/depot.state";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { AdditionalIncome } from "@page-components/onboarding/PersonalData/additional-income";
import {
  LABEL_INFO,
  MARRIED_STATUSES,
  RADIO_POLITICALLY,
  RULES
} from "@page-components/onboarding/PersonalData/constants";
import { Country } from "@page-components/onboarding/PersonalData/country";
import { JobStatus } from "@page-components/onboarding/PersonalData/job-status";

import { ModalFormContainer } from "../ModalFormContainer";
import type { FormValues } from "./usePersonalDataModalForm";
import { usePersonalDataModalForm } from "./usePersonalDataModalForm";

interface EditPersonalDataModalProps {
  userUri?: string;
  destroyModal: () => void;
}

export const EditPersonalDataModal: React.FunctionComponent<
  EditPersonalDataModalProps
> = ({ userUri, destroyModal }) => {
  if (!userUri) throw new Error("Expected userUri to be set.");
  const { form, onFinish, contextHolder, onCancel } = usePersonalDataModalForm(
    destroyModal,
    userUri
  );

  const [, depot] = useAsyncSelector(depotForUserUriState(userUri));

  const isUnderage = !!(depot && isUnderageDepot(depot));

  return (
    <ModalFormContainer<FormValues>
      title={
        isUnderage ? "Persönliche Angaben des Kindes" : "Persönliche Angaben"
      }
      submitBtnLabel="Persönliche Daten speichern"
      submitBtnIcon={<LockOutlined style={{ fontSize: 22 }} />}
      isSubmit={false}
      form={form}
      onFinish={onFinish}
      onCancel={onCancel}
    >
      {isUnderage && (
        <InputField
          style={{ maxWidth: 400 }}
          label="E-Mail-Adresse (optional)"
          name="email"
          rules={RULES.emailJunior}
        />
      )}

      <FormGroup spans={[{ md: 16 }, { md: 8 }]}>
        <InputField label="Straße *" name="street" rules={RULES.street} />

        <InputField
          label="Hausnummer *"
          name="streetNr"
          rules={RULES.streetNr}
          normalize={(value, prevVal, prevVals) => value.replace(/ /g, "")}
        />
      </FormGroup>
      <FormGroup spans={[{ md: 8 }, { md: 16 }]}>
        <InputField label="PLZ *" name="zipCode" rules={RULES.zipCode} />

        <InputField label="Ort *" name="city" rules={RULES.city} />
      </FormGroup>
      <FormGroup spans={[{ md: 16 }, { md: 8 }]}>
        <Country
          label="Land *"
          name="country"
          rules={RULES.country}
          validateTrigger="onChange"
          validateFirst
        />
        <SelectField
          label="Familienstand *"
          name="maritalStatus"
          rules={RULES.maritalStatus}
          data={MARRIED_STATUSES}
        />
      </FormGroup>
      <FormGroup spans={[{ md: 12 }, { md: 12 }]}>
        <PhoneField
          name="mobile"
          info={{
            ...LABEL_INFO.mobile,
            text: LABEL_INFO.mobile.text.replace("im vorherigen Schritt ", "")
          }}
          label={`${LABEL_INFO.mobile.title} *`}
          rules={RULES.mobile}
          validateTrigger="onBlur"
        />

        <PhoneField label="Telefon" name="phone" />
      </FormGroup>
      <JobStatus />
      <AdditionalIncome
        isUnderage={isUnderage}
        form={form}
        selectedAdditionalIncomes={form.getFieldValue("additionalIncome")}
      />
      <RadioGroup
        name="politicallyExposed"
        rules={RULES.politicallyExposed}
        info={
          isUnderage
            ? LABEL_INFO.politicallyExposedUnderage
            : LABEL_INFO.politicallyExposed
        }
        label={
          isUnderage
            ? `${LABEL_INFO.politicallyExposedUnderage.title} *`
            : `${LABEL_INFO.politicallyExposed.title} *`
        }
        items={RADIO_POLITICALLY}
      />
      {contextHolder}
    </ModalFormContainer>
  );
};
