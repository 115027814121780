import getKnowledgeDealingsLabel from "@src/page-components/onboarding/Knowledge/getKnowledgeDealingsLabel";
import { ProfileKeyValue } from "@src/page-components/onboarding/Summary/ProfileKeyValue";
import { getConfirm } from "@src/page-components/onboarding/Summary/helpers";
import type { ExperienceRangeValue } from "smavesto.core";

type KnowledgeEntriesItemProps = {
  name: string;
  customPreLabel?: string;
  experienceItem?: {
    knowhow: boolean;
    noticed: boolean;
    dealings: ExperienceRangeValue;
  };
};

const KnowledgeProfileKeyValueItem: React.FunctionComponent<
  KnowledgeEntriesItemProps
> = ({ experienceItem, name, customPreLabel }) => {
  return (
    <>
      <ProfileKeyValue
        alignSelectionBottom
        label={`${customPreLabel || `Erfahrungen in`} ${name}:`}
      >
        {getConfirm(experienceItem?.knowhow)}
      </ProfileKeyValue>

      {experienceItem?.knowhow === true ? (
        <ProfileKeyValue
          alignSelectionBottom
          label={`Anzahl der Geschäfte mit ${name} in den letzten drei Jahren:`}
        >
          {getKnowledgeDealingsLabel(experienceItem?.dealings)}
        </ProfileKeyValue>
      ) : (
        <ProfileKeyValue alignSelectionBottom label={`Kenntnisse von ${name}:`}>
          {getConfirm(experienceItem?.noticed)}
        </ProfileKeyValue>
      )}
    </>
  );
};

export default KnowledgeProfileKeyValueItem;
