import type { FormItemProps } from "antd";
import { Select } from "antd";
import type { FC } from "react";
import { useSetRecoilState } from "recoil";

import { SelectField } from "@components/form/inputs/form-fields/SelectField";
import { countriesQuery } from "@states/common.state";
import { countryCodeState } from "@states/profile.state";
import { useAsyncSelector } from "@hooks/useAsyncSelector";

export const Country: FC<FormItemProps> = props => {
  const [, countries = []] = useAsyncSelector(countriesQuery);

  const setCountryCode = useSetRecoilState(countryCodeState);

  const onCountryChange = (code: unknown) =>
    typeof code === "string" && setCountryCode(code);

  return (
    <SelectField {...props} onChange={onCountryChange}>
      {countries.map(item => (
        <Select.Option key={item.code} value={item.code}>
          {item.name}
        </Select.Option>
      ))}
    </SelectField>
  );
};
